import { useForm } from '@inertiajs/vue3'
import { Link, usePage } from '@inertiajs/vue3'
import { router } from '@inertiajs/vue3'

var global = null;
export default {

    install: (app) => {
        global = app.config.globalProperties;
      },
    
    confirmationDelete: function (callback) {
    
        global.$swal.fire({
            title: global.$t('Are you sure?'),
            text: global.$t('You won\'t be able to revert this!'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: global.$t('Yes, delete it!'),
            cancelButtonText: global.$t('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                callback(true);
            } else {
                callback(false);
            }
        });

        

    },
    toast: function(title, icon = 'success') {
        global.$toast.fire({
            title: title,
            icon: icon
        });
    }
    
}